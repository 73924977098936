import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ServiceLoader } from 'components/common/Loading';
import { Layout } from 'components/Layout';
import { RoutePaths } from './RoutePaths';
import EditDynamicMenuLayout from 'components/Pages/DynamicMenu/EditDynamicMenu';

const HomeLayout = lazy(() => import('components/Pages/Home/HomeLayout'));
const CreatePageLayout = lazy(() => import('components/Pages/CreatePage/CreatePageLayout'));
const EditPageLayout = lazy(() => import('components/Pages/EditPage/EditPageLayout'));
const DynamicMenuLayout = lazy(() => import('components/Pages/DynamicMenu/DynamicMenuLayout'));
const CreateDynamicMenuLayout = lazy(() => import('components/Pages/DynamicMenu/CreateDynamicMenuLayout'));
const NotAuthorized = lazy(() => import('components/Pages/Errors/NotAuthorized'));
const NotFound = lazy(() => import('components/Pages/Errors/NotFound'));

export const AppRoutes: React.FC = () => (
    <Layout>
        <Suspense fallback={<div className="d-flex justify-content-center"><ServiceLoader /></div>}>
            <Routes>
                <Route path={RoutePaths.Home.route} element={<HomeLayout />} />
                <Route path={RoutePaths.Pages.Create.route} element={<CreatePageLayout />} />
                <Route path={RoutePaths.Pages.Edit.route} element={<EditPageLayout />} />
                <Route path={RoutePaths.DynamicMenus.route} element={<DynamicMenuLayout />} />
                <Route path={RoutePaths.DynamicMenus.Create.route} element={<CreateDynamicMenuLayout />} />
                <Route path={RoutePaths.DynamicMenus.Edit.route} element={<EditDynamicMenuLayout />} />
                <Route path={RoutePaths.DynamicMenus.Item.Edit.route} element={<EditDynamicMenuLayout />} />
                <Route path={RoutePaths.DynamicMenus.Item.Add.route} element={<EditDynamicMenuLayout />} />
                <Route path={RoutePaths.Errors.NotAuthorized.route} element={<NotAuthorized />} />
                <Route path={RoutePaths.Errors.NotAuthorizedContactUs.route} element={<NotAuthorized isDisplayTryAgain={false} />} />
                <Route path={RoutePaths.Errors.NotFound.route} element={<NotFound />} />
            </Routes>
        </Suspense>
    </Layout>
);
