import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SgwtAccountCenter } from './SgwtAccountCenter';
import { RoutePaths } from 'routes/RoutePaths';
import classNames from 'classnames';
import { Menu } from './Menu';

export const Header: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    return <header className="header position-relative">
        <nav className="navbar border-bottom border-top">
            <div className="navbar-title">
                <NavLink
                    to={RoutePaths.Home.url()}
                    className={({ isActive }) => classNames('navbar-title-link', { active: isActive })}
                >
                    <div className="navbar-logo">
                        <img src="https://shared.sgmarkets.com/assets/images/logo/socgen_logo.svg" alt="SG logo Glyph" />
                    </div>

                    <div className="navbar-title-divider" />
                    <div className="navbar-service-name">
                        Insight
                        <br />
                        Admin
                    </div>
                </NavLink>

                <button onClick={toggle} className={classNames('navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center', { show: isOpen })}>
                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="2" y="6" width="20" height="2" fill="currentColor"></rect>
                        <rect x="2" y="11" width="20" height="2" fill="currentColor"></rect>
                        <rect x="2" y="16" width="20" height="2" fill="currentColor"></rect>
                    </svg>
                </button>
                <Menu className={classNames('navbar-menu-dropdown dropdown-menu w-100 top-100 position-absolute', { show: isOpen })} />
            </div>
            <div className="navbar-content">
                <Menu className="navbar-navigation" />
            </div>
            <div className="navbar-toolbar">
                <SgwtAccountCenter />
            </div>
        </nav>
    </header>;
};
