import * as React from 'react';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { HelpCenter } from './HelpCenter';
import { Footer } from './Footer';
import { SplashScreen } from './SplashScreen';
import { SgwtConnect } from './SgwtConnect';
import { Header } from './Header';


interface IProps {
    children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {

    return (
        <div>
            <Header />
            <div className="d-flex justify-content-center px-4" style={{ minHeight: 'calc(100vh - var(--header-height))' }}>
                <ErrorBoundary>{children}</ErrorBoundary>
            </div>
            <Footer />
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeButton={false}
                icon={false}
                newestOnTop
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <HelpCenter />
            <SgwtConnect />
            <SplashScreen />
        </div >);
};
