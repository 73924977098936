import { useAppContext } from 'components/common/AppProvider';
import { toastError } from 'components/common/Toaster';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import * as React from 'react';
import { useEffect } from 'react';
import { getNavigateAs, removeNavigateAs, setNavigateAs } from 'services/NavigateAsService';
import { hasRight, Permissions } from 'services/RightsService';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { InsightSelectors } from 'store/Normalizr/Selectors';
import { getNavigateAsPersons } from 'store/Persons/PersonsThunk';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sgwt-account-center': {
                    id?: string;
                    'available-languages'?: string;
                    'user-dropdown-links'?: any;
                    authentication?: string;
                    environment?: string;
                    language?: string;
                    mode?: 'sg-markets';
                    debug?: string;
                    ref?: (elt: HTMLElement) => void;
                    'navigate-as'?: boolean;
                    'navigate-as-list'?: string;
                    'navigate-as-user'?: string;
                };
            }
        }
    }

    interface DocumentEventMap {
        [navigateAsEventName]: CustomEvent<INavigateAsEvent>;
        [stopNavigateAsEventName]: Event;
    }
}

const navigateAsEventName = 'sgwt-account-center--navigate-as-select-user';
const stopNavigateAsEventName = 'sgwt-account-center--stop-navigation-as';
interface INavigateAsEvent {
    user: {
        id: string,
        name: string,
        company: string,
    }
}

export const SgwtAccountCenter: React.FC = () => {
    const {
        dispatch,
        state: {
            appContext: {
                environment,
                loggedUserId,
            },
            entities,
            persons: {
                navigateAs,
            },
        },
    } = useAppContext();
    const loggedUser = InsightSelectors.getLoggedUser(loggedUserId, entities.insight);
    if (loggedUserId && !loggedUser?.impersonatedBy && getNavigateAs()) {
        toastError('Impersonation failed. Please come back later to use this functionnality.', 'Impersonation Failed');
        setTimeout(() => {
            removeNavigateAs();
        }, 3000);
    }
    const navigateAsPersons = InsightSelectors.getPersonArray(navigateAs.data, entities.insight);
    const canNavigateAs = hasRight(loggedUser?.impersonatedBy || loggedUser, Permissions.Administrator);

    const handleNavigateAs = (event: CustomEvent<INavigateAsEvent>) => {
        setNavigateAs(event.detail.user.id);
    };

    const handleStopNavigateAs = () => {
        removeNavigateAs();
    };

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);
        document.addEventListener(navigateAsEventName, handleNavigateAs);
        document.addEventListener(stopNavigateAsEventName, handleStopNavigateAs);

        return () => {
            document.removeEventListener(navigateAsEventName, handleNavigateAs);
            document.removeEventListener(stopNavigateAsEventName, handleStopNavigateAs);
        };
    }, []);

    useEffect(() => {
        if (canNavigateAs) {
            dispatch(getNavigateAsPersons()).catch(() => void 0);
        }
    }, [canNavigateAs]);

    const navigateAsList = navigateAsPersons.map(p => ({
        id: p.icId,
        name: `${p.lastName} ${p.firstName}`,
    }));

    const navigateAsIcId = getNavigateAs();
    const navigateAsUser = navigateAsList.find(user => user.id === navigateAsIcId);

    return (
        <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
            <sgwt-account-center
                id="sgwtAccountCenter"
                language="en"
                available-languages="en"
                authentication="sg-connect-v2"
                mode="sg-markets"
                environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
                navigate-as={canNavigateAs}
                navigate-as-list={JSON.stringify(navigateAsList)}
                navigate-as-user={JSON.stringify(navigateAsUser)}
            />
        </WidgetLazyLoader>
    );
};
